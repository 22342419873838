import React from 'react';
import { Link } from 'gatsby';
import Logo from '../assets/original-logo.png';

import '../styles/404Styles.css';

const NotFound = () => (
  <div className="center">
    <div className="centerContainer">
      <img src={Logo} alt="Nearshore Code" className="logo" />
    </div>
    <div className="centerContainer">
      <h1>There seems to be an error.</h1>
    </div>
    <div className="centerContainer">
      <p>
        <Link to="/">Go Back to Main Page</Link>
      </p>
    </div>
  </div>
);

export default NotFound;
